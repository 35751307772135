import { Checkbox } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import FAW from "../../../../../containers/faw";
import { selectOrDeselectLesson } from "../../CreateAssignmentFlowUtilities";

import config from "../../../../../common/config";
import { lessonViewerWindowRef as lessonViewerWindowRefA } from "../../../../../redux/actions";
import {
  openRequestedTab,
  previewLessonUrl,
} from "../../../../../services/http";

const mapStateToProps = (state, ownProps?) => ({
  lessonViewerWindowRef: state.gui.lessonViewerWindowRef,
});

const PreviewLessonBtn = ({ lessonId, lessonViewerWindowRef, dispatch }) => {
  return (
    <p style={{ textAlign: "center" }}>
      <span style={{ position: "relative" }}>
        <button
          className='btn btn-sm btn-primary'
          onClick={(e) => {
            e.stopPropagation();
            const tabRef = openRequestedTab({
              // ignore first character of lessonId, which is the lesson source (I or E)
              url: previewLessonUrl(lessonId.slice(1)),
              windowRef: lessonViewerWindowRef,
              windowName: config.LESSONVIEWER_WINDOW_NAME,
            });
            dispatch(lessonViewerWindowRefA(tabRef));
          }}
          disabled={!lessonId.startsWith("I")}
          lesson-preview-data-title={
            lessonId.startsWith("I")
              ? "Preview lesson"
              : "Lesson preview only available for ABii Lessons at this time"
          }
        >
          <FAW icon='external-link-alt' />
        </button>
      </span>
    </p>
  );
};

const SelectLessonCheckbox = ({
  lessonType,
  isLessonSelected,
  handleOnChange,
}) => {
  return (
    <Checkbox
      onChange={(e) => {
        e.stopPropagation();
        handleOnChange(e, lessonType);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleOnChange(e, lessonType);
        }
      }}
      checked={isLessonSelected}
      size='small'
    />
  );
};

const StepOneLessonRow = ({
  lesson,
  lessons,
  setLessons,
  index,
  numInDisplayTable,
  selectedLessons,
  setSelectedLessons,
  numberOfLessonsSelected,
  setErrorMsg,
  isPopup = false,
  setPopupInfo,
  setShowPopupInfo,
  dispatch,
  lessonViewerWindowRef,
}) => {
  const handleSelectLesson = (e, lessonType) => {
    if (reachedMaxSelected(index, lessonType.toLowerCase())) {
      setMaxLessonsErrMsg();
      return;
    }
    selectOrDeselectLesson(
      lessons,
      setLessons,
      selectedLessons,
      setSelectedLessons,
      lessonType,
      lesson
    );
  };

  // only runs if the user attempts to add 11 or more lessons.
  const setMaxLessonsErrMsg = () => {
    setErrorMsg("No more than 10 lessons can be selected.");
  };

  /**
   * Determines if the user is attempting to select 11 or more lessons, which
   * is not allowed.
   * @param {int} index
   * @param {string} type
   * @returns boolean
   */
  const reachedMaxSelected = (index, type) => {
    if (index === -1) return false;
    if (lessons[index][type]) {
      return !lessons[index][type].selected && numberOfLessonsSelected >= 10;
    }
    // if it gets here, it was a deselection from the popup.
    return false;
  };

  return (
    <div>
      <div
        className={`choose_lesson_table_option_row ${
          lesson.tutorial.selected ? "selected_tut" : ""
        }`}
        style={{
          display: isPopup && !lesson.tutorial.selected ? "none" : "grid",
        }}
        onClick={(e) => {
          handleSelectLesson(null, "Tutorial");
        }}
      >
        <p className='index'>{numInDisplayTable}</p>
        {/* TODO: create separate components for each row type (tutorial, assessment) and use them here */}
        <p>{lesson.course_name}</p>
        <p>{lesson.unit}</p>
        <p style={{ textAlign: "center" }}>
          {lesson.course_name.startsWith("SEL") ? (
            <>{lesson.standard_description}</>
          ) : (
            <>
              {lesson.standard}
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  padding: "0px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setPopupInfo({
                    header: `Standard: ${lesson.standard}`,
                    text: `${lesson.standard_description}`,
                  });
                  setShowPopupInfo(true);
                }}
              >
                <FAW icon='info-circle' className='info-circle' />
              </button>
            </>
          )}
        </p>
        <p style={{ textAlign: "center" }}>Tutorial</p>
        <p>{lesson.tutorial.lesson_name}</p>

        <PreviewLessonBtn
          lessonId={lesson.tutorial.lesson_id}
          lessonViewerWindowRef={lessonViewerWindowRef}
          dispatch={dispatch}
        />

        <SelectLessonCheckbox
          lessonType='Tutorial'
          isLessonSelected={lesson.tutorial.selected}
          handleOnChange={handleSelectLesson}
        />
      </div>

      <div
        className={`choose_lesson_table_option_row ${
          lesson.assessment.selected ? "selected_asm" : ""
        } asm_row`}
        style={{
          display: isPopup && !lesson.assessment.selected ? "none" : "grid",
        }}
        onClick={() => {
          handleSelectLesson(null, "Assessment");
        }}
      >
        <p className='index'>
          {isPopup && !lesson.tutorial.selected
            ? numInDisplayTable
            : numInDisplayTable + 1}
        </p>
        <p>{lesson.course_name}</p>
        <p>{lesson.unit}</p>
        <p style={{ textAlign: "center" }}>
          {lesson.course_name.startsWith("SEL") ? (
            <>{lesson.standard_description}</>
          ) : (
            <>
              {lesson.standard}
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  padding: "0px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setPopupInfo({
                    header: `Standard: ${lesson.standard}`,
                    text: `${lesson.standard_description}`,
                  });
                  setShowPopupInfo(true);
                }}
              >
                <FAW icon='info-circle' className='info-circle' />
              </button>
            </>
          )}
        </p>
        <p style={{ textAlign: "center" }}>Assessment</p>
        <p>{lesson.assessment.lesson_name}</p>
        <PreviewLessonBtn
          lessonId={lesson.assessment.lesson_id}
          lessonViewerWindowRef={lessonViewerWindowRef}
          dispatch={dispatch}
        />

        <SelectLessonCheckbox
          lessonType='Assessment'
          isLessonSelected={lesson.assessment.selected}
          handleOnChange={handleSelectLesson}
        />
      </div>
    </div>
  );
};

export default compose(connect(mapStateToProps))(StepOneLessonRow);
